/* nunito-sans-regular - latin */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./NunitoSans-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./NunitoSans-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./NunitoSans-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Nunito Sans Bold";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./NunitoSans-Bold.ttf") format("woff2"),
    /* Super Modern Browsers */ url("./NunitoSans-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./NunitoSans-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}

.-Boldtext-9xl {
  font-family: "Nunito Sans";
  font-size: 128px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-9xl {
  font-family: "Nunito Sans";
  font-size: 128px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-8xl {
  font-family: "Nunito Sans";
  font-size: 96px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-7xl {
  font-family: "Nunito Sans";
  font-size: 72px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-7xl {
  font-family: "Nunito Sans";
  font-size: 72px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-6xl {
  font-family: "Nunito Sans";
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-6xl {
  font-family: "Nunito Sans";
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-5xl {
  font-family: "Nunito Sans";
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-5xl {
  font-family: "Nunito Sans";
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-4xl {
  font-family: "Nunito Sans";
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-4xl {
  font-family: "Nunito Sans";
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-3xl {
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-3xl {
  font-family: "Nunito Sans";
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-2xl {
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-2xl {
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-xl {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regular-text-xl {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-lg {
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regular-text-lg {
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-base {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regular-text-base {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-sm {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regulartext-8xl {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regular-text-sm {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Boldtext-xs {
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

.-Regular-text-xs {
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate-50);
}

  